import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Form } from 'react-bootstrap';
import CustomYearDropdown from "./CustomYearDropdown";

function Datepicker() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dob, setDob] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date instanceof Date && !isNaN(date)) {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
      setDob(formattedDate);
    } else {
      console.error("Invalid Date:", date);
      setDob("");
    }
  };

  return (
    <div>
      <DatePicker
        wrapperClassName="custom-datepicker-wrapper"
        showYearDropdown
        customInput={<Form.Control type="text" placeholder="mm/dd/yyyy" />}
        yearDropdownItemNumber={10}
        showMonthDropdown
        monthDropdownItemNumber={12}
        selected={selectedDate}
        onChange={handleDateChange}
        showYearDropdownScrollable={true}
        dropdownMode="select"
        dateFormatCalendar="MMMM"
        minDate={new Date('1923-01-01')}
        maxDate={new Date('2008-12-31')}
        scrollableYearDropdown
        dateFormat="MM/dd/yyyy" // Set the date format
        customYearDropdown={<CustomYearDropdown year={selectedYear} onChange={(year) => setSelectedYear(year)} />}
      />
      <input type="hidden" name="DOB" value={dob ? dob.toString() : ''} />
    </div>
  );
}

export default Datepicker;
