import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const scrollToTop = () => {

  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  //window.location.replace(process.env.REACT_APP_BASE_URL + "Signup");
}; 
const bottomToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smoothly scrolling
  });
  //window.location.replace(process.env.REACT_APP_BASE_URL + "Eula");
};
const AboutPopup = ({ isOpen, onClose }) => {
  
  return (
    <div className={`popup ${isOpen ? 'open' : ''}`} >
      <div className="popup-content abpopup">
        {/* <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <h2>About Us</h2> */}
        <Row>
        <Col><h2>About Us</h2></Col>
       
        <Col><span className="close-btn" onClick={onClose}>
          &times;
        </span></Col>
      </Row>
        <p>DBA Name:  BOGOmazing <br />
            5422 Rogers Ave, Suite 2 <br />
            Port Orange, FL  32127 <br />
            213-926-9918 <br />
            contact@bogomazing.com <br />
            Membership Pricing:  $29.95/Month <br />
            Cancel Anytime <br />
            Set Up Your Account At   <Link onClick={scrollToTop} to={process.env.REACT_APP_BASE_URL+"SignUp"} className='text-reset'>
            https://www.bogomazing.com/Signup
                </Link> <br />
            Shipping Method:  None <br />
            <Link onClick={bottomToTop} to={process.env.REACT_APP_BASE_URL+"Eula"} className='text-reset'>
            Click Here To View Our EULA (End-User License Agreement)
                </Link><br />
            Refund Policy:  If you do not have any redemptions at any restaurant<br /> in 30 days, 
            you may request a refund and cancel your membership <br /> for the last 30 days  by emailing  
            refunds@bogomazing.com <br />your full name and BOGOmazing Member Number.</p> 
      </div>
    </div>
  );
};

export default AboutPopup;
