import React from 'react';

const Popup = ({ isOpen, onClose }) => {
  return (
    <div className={`popup ${isOpen ? 'open' : ''}`}>
      <div className="popup-content">
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <h2>Terms & Conditions</h2>
        <p>You Can Easily Cancel Anytime Through Your BOGOmazing Profile <br />
          $31.20 ($29.95+$1.25 Processing Fee) Will Be Charged Every 30 Days <br />
          Charge Appears At BOGOmazing App <br />
          Membership Is Valid At 100% Of All Restaurants Listed In The BOGOmazing App <br />
          Only 1 IP Address Per Membership.  Sharing Your Login <br />
          Information Will Automatically Disable & Close Your Account 
        </p>
      </div>
    </div>
  );
}; 

export default Popup;
