import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
  
const TermsConditions = () => {
    return (

        <Container>
            <Row>
                <div className='text-danger'>
                {/* <p className="terms">You Can Cancel Anytime</p>
                <p className="terms">$29.95 Billed Every 30 Days Appearing As BOGOmazing App</p>
                <p className="terms">Earn $29.95 Billing Credit For Each Referral I Understand My Membership Can Be Cancelled If I Do Not Show</p>

                <p className="terms">Good Faith & Integrity When Using BOGOmazing App</p>
                 */}
                   
                    <p className="terms">You Can Easily Cancel Anytime Through Your BOGOmazing Profile</p>
                    <p className="terms">$31.20 ($29.95+$1.25 Processing Fee)</p>
                    <p className="terms">Will Be Charged Every 30 Days</p>
                    <p className="terms">Charge Appears At BOGOmazing App</p>
                    <p className="terms">Membership Is Valid At 100% Of All Restaurants Listed In The BOGOmazing App</p>
                    <p className="terms">Only 1 IP Address Per Membership.  Sharing Your Login Information Will Automatically</p> 
                    <p className="terms">Disable & Close Your Account</p>    
                </div>



            </Row>
        </Container>

    )
}

export default TermsConditions;
