import React from 'react';

const Error = () => {

  return(
    <>
    <h1>404... Page Not Found</h1>
    </>
  )
}
export default Error;
