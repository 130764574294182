import React from "react";
import { useState } from "react";

function CustomYearDropdown({ year, onChange }) {
  const years = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear; i >= 1923; i--) {
    years.push(i);
  }

  return (
    <div className="custom-year-dropdown">
      <select
        value={year}
        onChange={(e) => onChange(e.target.value)}
      >
        {years.map((y) => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CustomYearDropdown;
