import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

//import {Link} from 'react-router-dom';
import Popup from './Popup';
function Work() {
  
 const [validated, setValidated] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  useEffect(() => {
    // Load Collect.js script when the component is mounted
    const script = document.createElement('script');
    script.src = 'https://secure.nmi.com/token/Collect.js';
    script.async = true;
    script.setAttribute('data-tokenization-key', 'Y99RE5-T9D5qK-PFQ85n-VsGy4y');
    script.onload = () => {
      // Collect.js script has loaded
      window.CollectJS.configure({
        callback: function (response) {
          console.log('Tokenization successful');
          console.log(response);
          document.getElementById('paymentTokenInfo').innerHTML =
            '<b>Payment Token:</b> ' + response.token +
            '<br><b>Card:</b> ' + response.card.number +
            '<br><b>BIN/EIN:</b> ' + response.card.bin +
            '<br><b>Expiration:</b> ' + response.card.exp +
            '<br><b>Hash:</b> ' + response.card.hash +
            '<br><b>Card Type:</b> ' + response.card.type +
            '<br><b>Check Account Name:</b> ' + response.check.name +
            '<br><b>Check Account Number:</b> ' + response.check.account +
            '<br><b>Check Account Hash:</b> ' + response.check.hash +
            '<br><b>Check Routing Number:</b> ' + response.check.aba;
        },
        variant: 'inline',
        googleFont: 'Abel',
        invalidCss: {
          color: '#B40E3E',
        },
        validCss: {
          color: '#14855F',
        },
        customCss: {
          'border-color': '#FFFFFF',
          'border-style': 'solid',
        },
        focusCss: {
          'border-color': '#1CC48B',
          'border-style': 'solid',
          'border-width': '3px',
        },
        fields: {
          cvv: {
            placeholder: 'CVV',
          },
          ccnumber: {
            placeholder: 'Credit Card',
          },
          ccexp: {
            placeholder: 'MM / YY',
          },
        },
      });
    };

    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []); 



  return (
    <div className="app">
    <br />
    <br />
    <div className="login-form">
      <div className="title text-center p-4 mb-4 fs-4">BOGOmazing Subscription Payment Information</div>
      <div className='container p-4'>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} id="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control required type="text" placeholder="Name" />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} id="formGridEmail">
              <Form.Label>Email</Form.Label>
              {/* <Form.Control required pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/" type="text" placeholder="Email" value="angelabrigid@yopmail.com" />*/}
              <Form.Control required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}" type="text" placeholder="Email" value="angelabrigid@yopmail.com" /> 
              <Form.Control.Feedback type="invalid">
                Please Provide A Valid Email.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3" id="payment-fields">
            <Form.Group as={Col} id="formGridState">
              <Form.Label>Credit Card Number</Form.Label>
              <Form.Control required type="text" id="ccnumber" className="payment-field" placeholder="" />

              <Form.Control.Feedback type="invalid">
                Credit Card is Required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} id="formGridCity">
              <Form.Label>CVV</Form.Label>
              <Form.Control required id="cvv" type="text" className="payment-field" placeholder="" />

              <Form.Control.Feedback type="invalid">
                CVV is Required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} id="formGridEmail">
              <Form.Label>Exp Month</Form.Label>
              <Form.Control required id="ccexp" className="payment-field" pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/" type="text" placeholder="02" />
              <Form.Control.Feedback type="invalid">
                Please Provide Exp Month.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} id="formGridPassword">
              <Form.Label>Exp Year</Form.Label>
              <Form.Control required type="text" placeholder="2025" />
              <Form.Control.Feedback type="invalid">
                Please Provide A Valid Email.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} id="formGridEmail">
              <Form.Label>Billing Address</Form.Label>
              <Form.Control required type="text" placeholder="Billing Address" />
              <Form.Control.Feedback type="invalid">
                Please Provide Billing Address.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} id="formGridPassword">
              <Form.Label>Billing Zip Code</Form.Label>
              <Form.Control required type="text" placeholder="Billing Zip Code" />
              <Form.Control.Feedback type="invalid">
                Please Provide Billing Zip Code.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div id="paymentTokenInfo"></div>

          <Form.Group className="mb-3" id="formGridCheckbox">
            <Form.Check
              type="checkbox"
              label="I Understand My Card Will Be Charged ($29.95) Every 30 Days Until I Cancel My Membership."
              defaultChecked={isChecked}
              onChange={handleChange}
            />
            <Form.Check
              type="checkbox"
              label={
                <>
                  I Agree the BOGOmazing Terms & Conditions.{' '}
                  <a className="text-color" onClick={handleOpenPopup} target="_blank" rel="noopener noreferrer">
                    Click Here To View
                  </a>
                </>
              }
              defaultChecked={isChecked}
              onChange={handleChange}
            />
            {isPopupOpen && <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />}
          </Form.Group>
          <div className="text-center">
            <Button variant="danger" type="submit">
              Submit BOGOmazing Subscription Payment Information
            </Button>
          </div>
        </Form>
      </div>
    </div>
    <br />
    <br />
  </div>
  );
}

export default Work;
