/* global CollectJS */
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, { useState, useEffect } from 'react';
//import {Link} from 'react-router-dom';
import Popup from './Popup';
import { useParams} from 'react-router-dom';
import { FaCcVisa, FaCcMastercard, FaCcDiscover, FaCcAmex } from 'react-icons/fa'; // Import card icons



import {ToastContainer,toast} from 'react-toastify';
import 'react-credit-cards/es/styles-compiled.css'; // Import the styles
import Cards from 'react-credit-cards'; // Import the credit card component
/*import InputMask from 'react-input-mask';*/

function Payment() {
  const [validated, setValidated] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { token } = useParams();
  const [selectedCardType, setSelectedCardType] = useState('visa');
  const [name , setName] = useState();
  const [email , setEmail] = useState();

  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiry, setCardExpiry] = useState('');
  const [cardCVC, setCardCVC] = useState('');
  const [focus, setFocus] = useState('');

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };
  fetch(process.env.REACT_APP_API_URL+'getDataFromToken/'+token, {
    method: 'GET',
    mode: 'cors', 
    
  })
    .then((result) => {

        result.json().then((resp) => {
         // console.log(resp.userName)
          setName(resp.userName)
          setEmail(resp.userEmail)
      })
    })
    .catch((error) => {
      console.error(error);
    });
  const handleCardInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'number':
        setCardNumber(value);
        break;
      case 'expiry':
        setCardExpiry(value);
        break;
      case 'cvc':
        setCardCVC(value);
        break;
      default:
        break;
    }
  };



 

    // const formatCardNumber = (inputValue) => {
    //   // Remove non-numeric characters from the input
    //   const numericValue = inputValue.replace(/\D/g, '');
    
    //   // Format the card number as '0000-0000-0000-0000'
    //   const formattedValue = numericValue
    //     .slice(0, 16)
    //     .replace(/(\d{4})/g, '$1-')
    //     .slice(0, 19);
    
    //   return formattedValue;
    // };
    
    const handleCardNumberChange = (e) => {
      const formattedValue = formatCardNumber(e.target.value);
      // Update the input field with the formatted value
      e.target.value = formattedValue;
    };


    function formatCardNumber(inputValue, selectedCardType) {
      const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
      let formattedValue = '';
      let placeholder = "0000-0000-0000-0000";
      switch (selectedCardType) {
        case 'visa':
          placeholder = "0000-0000-0000-0000";
          formattedValue = numericValue.replace(/(\d{4})/g, '$1-').slice(0, 19);
          break;
        case 'mastercard':
          placeholder = "0000-0000-0000-0000";
          formattedValue = numericValue.slice(0, 16).replace(/(\d{4})/g, '$1-').slice(0, 19);
          break;
        case 'discover':
          placeholder = "0000-0000-0000-0000";
          formattedValue = numericValue.slice(0, 16).replace(/(\d{4})/g, '$1-').slice(0, 19);
          break;
        case 'amex':
          placeholder = "0000-000000-00000";
          formattedValue = numericValue.slice(0, 15).replace(/(\d{4})(\d{6})(\d{5})/, '$1-$2-$3');
          break;
        default:
          placeholder = "0000-0000-0000-0000";
          formattedValue = numericValue.slice(0, 16).replace(/(\d{4})/g, '$1-').slice(0, 19);
          break;
      }
    
      return formattedValue;
    }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
  
    if (form.checkValidity() === false) {
    //  event.preventDefault();
      event.stopPropagation();
    }else{
      
        const data = new FormData(event.target);
        data.append('token', token);

        data.append('ccnumber', cardNumber);
       // data.append('cardExpiry', cardExpiry);
        //data.append('cardCVC', cardCVC);

      
        fetch(process.env.REACT_APP_API_URL+'payment', {
          method: 'POST',
          mode: 'cors', 
          body: data, 
        })
          .then((result) => {
    
              result.json().then((resp) => {
                toast(resp.message);
                if(resp.status==="error"){ 
                  console.log(resp.status)
                }
                else{
                
                     window.location.replace(process.env.REACT_APP_BASE_URL+"PaymentSuccess/"+token);
                } 
              
            })
          })
          .catch((error) => {
            console.error(error);
          });
    }

    setValidated(true);
  };
  

  return (
    <>

      <div className="app">
        <br />
        <br />
        <div className="login-form">
          <div className="title text-center p-4 mb-4 fs-4">BOGOmazing Restaurant Dining Monthly Subscription Payment Information
          </div>
          <div className='container p-4'>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/* <Cards
              number={cardNumber}
              name={name} // Display the card holder's name
              expiry={cardExpiry}
              cvc={cardCVC}
              focused={focus}
            /> */}
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control required type="text" placeholder="Name" value={name} disabled  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>  

                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control required pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/" name="email" type="text" placeholder="Email" value={email}  />
                  <Form.Control.Feedback type="invalid">
                    Please Provide A Valid Email.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Credit Card Type
                      {/* <div className="card-icons">
                      <FaCcVisa className={`card-icon ${cardNumber.startsWith('4') ? 'active' : ''}`} />
                      <FaCcMastercard className={`card-icon ${cardNumber.match(/^5[1-5]/) ? 'active' : ''}`} />
                      <FaCcDiscover className={`card-icon ${cardNumber.match(/^6011|65|64[4-9]|622126-622925|62213|622665-622925/) ? 'active' : ''}`} />
                      <FaCcAmex className={`card-icon ${cardNumber.match(/^3[47]/) ? 'active' : ''}`} />
                    </div> */}
                    </Form.Label>
                    <Form.Select
                        name="selectedCardType"
                        required
                        value={selectedCardType}
                        onChange={(e) => setSelectedCardType(e.target.value)}>
                        <option value="visa"><FaCcVisa className='card-icon'/> Visa</option>
                        <option value="mastercard"><FaCcMastercard className='card-icon'/> Mastercard</option>
                        <option value="discover"><FaCcDiscover className='card-icon'/> Discover</option>
                        <option value="amex"><FaCcAmex className='card-icon'/> American Express</option>
                      </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Credit Card Number </Form.Label>

                    <Form.Control
                        required
                        type="tel"
                        name="number"
                        placeholder="0000-0000-0000-0000"
                        value={formatCardNumber(cardNumber, selectedCardType)}
                        onChange={handleCardInputChange}
                        onFocus={() => setFocus('number')}
                      />

                      <Form.Control.Feedback type="invalid">
                        Credit Card is Required.
                      </Form.Control.Feedback>
                  </Form.Group>
                  </Row>
                <Row className="mb-3">  
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Exp Month</Form.Label>
                 
                  <Form.Select name="month" required type="select">
                      <option value="01">1</option>
                      <option value="02">2</option>
                      <option value="03">3</option>
                      <option value="04">4</option>
                      <option value="05">5</option>
                      <option value="06">6</option>
                      <option value="07">7</option>
                      <option value="08">8</option>
                      <option value="09">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                  </Form.Select>
                  
                  <Form.Control.Feedback type="invalid">
                    Please Provide Exp Month.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Exp Year</Form.Label>
                  
                    <Form.Select name="year" required type="select">
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                        <option value="2031">2031</option>
                        <option value="2032">2032</option>
                        <option value="2033">2033</option>
                        <option value="2034">2034</option>
                        <option value="2035">2035</option>
                      </Form.Select>
                  <Form.Control.Feedback type="invalid">
                  Please Provide Exp Year.
                  </Form.Control.Feedback>
                </Form.Group>
               </Row>
              {/* <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Exp Month</Form.Label>
                  <Form.Control required pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/" type="text" placeholder="02" />
                  <Form.Control.Feedback type="invalid">
                    Please Provide Exp Month.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Exp Year</Form.Label>
                  <Form.Control required type="text" placeholder="2025" />
                  <Form.Control.Feedback type="invalid">
                    Please Provide A Valid Email.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row> */}

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Billing Address </Form.Label>
                  <Form.Control required type="text" placeholder="Billing Address" />
                  <Form.Control.Feedback type="invalid">
                    Please Provide Billing Address.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Billing Zip Code</Form.Label>
                    <Form.Control required type="text" placeholder="Billing Zip Code" pattern="[0-9]{5}" />
                    <Form.Control.Feedback type="invalid">  
                      Please Provide A Valid Billing Zip Code.
                    </Form.Control.Feedback>
                </Form.Group>   
              </Row>  
              <div id="paymentTokenInfo"></div>

              <Form.Group className="mb-3" id="formGridCheckbox">
                {/* <Form.Check type="checkbox" label="I Understand My Card Will Be Charged ($29.95) Every 30 Days Until I Cancel My Membership."  defaultChecked={isChecked}
                onChange={handleChange} /> */}
                <Form.Check type="checkbox" label="I Understand My Credit Card Will Be Charged $31.00 Per Month ($29.95 + $1.05 Service Fee) Every 30 Days Until I Cancel My Membership."  defaultChecked={isChecked}
                onChange={handleChange} />
                <Form.Check type="checkbox" label={
                  <>
                    I Agree the BOGOmazing Terms & Conditions. {' '}
                    <a class="text-color" onClick={handleOpenPopup} target="_blank" rel="noopener noreferrer">
                    Click Here To View
                    </a>
                    
                  </>
                }  defaultChecked={isChecked}
                onChange={handleChange} />


                {/*<Form.Check type="checkbox" label={
                  <>
                    I Agree the BOGOmazing Terms & Conditions {' '}
                    <a href={process.env.REACT_APP_BASE_URL+"Terms&Conditions"} onClick={handleOpenPopup} target="_blank" rel="noopener noreferrer">
                    Click Here To View
                    </a>
                    .
                  </>
                }  defaultChecked={isChecked}
              onChange={handleChange} />*/}


                {isPopupOpen && <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />}
              </Form.Group>
              <div className='text-center'>
                <Button variant="danger" type="submit">
                  Click Here To Submit Your BOGOmazing Subscription Payment Information
                </Button>
              </div>
            </Form>
          </div>
        </div>
          <br />
          <br />
        </div>
      
    </>
  );
}

export default Payment;