import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import GooglePlayIcon from '../../Images/Google play icon.png';
import AppStoreIcon from '../../Images/App store icon.png';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import SingleRowComponent from './SingleRowComponent';

const PaymentSuccess = () => {
  const { token } = useParams();
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'getMemberDetailByToken/' + token, {
      method: 'GET',
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((resp) => {
        setRowData(resp);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  return (
    <>
      <div className="app">
        <br />
        <br />

        <div className="login-form">
          <div className="title text-center p-4 mb-4 fs-4">BOGOmazing Payment Status</div>
          <Container>
            <div className='mx-auto mb-4 text-center'>
              <p className=''>Thank You For Becoming A BOGOmazing Member, A Confirmation Has Been Emailed To The Address Below. <br /> Please Download BOGOmazing By Clicking On Either Link Below</p>

              <Link to="https://apps.apple.com/in/app/bogomazing/id1620879147" className='text-reset'>
                <img src={AppStoreIcon} alt="AppStoreIcon" />
              </Link>&ensp;&ensp;&ensp;
              <Link to="https://play.google.com/store/apps/details?id=com.bogoMazing.app&pli=1" className='text-reset'>
                <img src={GooglePlayIcon} alt="GooglePlayIcon" />
              </Link>
            </div>
            
            <Table responsive="xl">
                <SingleRowComponent rowData={rowData} />
            </Table>
            <p>Card To Be Charged Every 30 Days Until Membership Is Cancelled.</p>
            <Form>
              <Form.Check
                required
                name="terms"
                label="You Will Receive Other Special Offers Per Your Request" checked
              />
            </Form>
          </Container>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}

export default PaymentSuccess;
