import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import React, { useEffect,useState } from 'react';
import { Link, useParams} from 'react-router-dom';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FindBogo() {
  const [validated, setValidated] = useState(false);
  const [users, setUser] = useState([]);
  const [referral, setReferral] = useState();
  //const [staff, setStaff] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [showStaffContent, setStaffContent] = useState(false);

  const { token } = useParams();
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');

  const [code1Error, setCode1Error] = useState('');
const [code2Error, setCode2Error] = useState('');
const [code3Error, setCode3Error] = useState('');

  const errorStyle = {
    borderColor: '#dc3545',
      padding: '0.375rem 1.75rem 0.375rem 0.75rem',
      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right calc(.375em + .1875rem) center',
      backgroundSize: 'calc(.75em + .375rem) calc(.75em + .375rem)', };
  
  const errorAlertStyle = {
    color: '#dc3545;',
  };

  const validateCodes = () => {
    let isValid = true;
  
    if (referral === "referral") {
      if (code1.length !== 2) {
        setCode1Error("Code 1 should be exactly 2 characters.");
        isValid = false;
      } else {
        setCode1Error('');
      }
  
      if (code2.length !== 3) {
        setCode2Error("Code 2 should be exactly 3 characters.");
        isValid = false;
      } else {
        setCode2Error('');
      }
  
      if (code3.length !== 5) {
        setCode3Error("Code 3 should be exactly 5 characters.");
        isValid = false;
      } else {
        setCode3Error('');
      }
    } else if (referral === "staff") {
      if (code1.length !== 2) {
        setCode1Error("Code 1 should be exactly 2 characters.");
        isValid = false;
      } else {
        setCode1Error('');
      }
  
      if (code2.length !== 3) {
        setCode2Error("Code 2 should be exactly 3 characters.");
        isValid = false;
      } else {
        setCode2Error('');
      }
  
      if (code3.length !== 5) {
        setCode3Error("Code 3 should be exactly 5 characters.");
        isValid = false;
      } else {
        setCode3Error('');
      }
    }
  
    return isValid;
  };
  

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'findBogoList').then((result) => {
      result.json().then((resp) => {
        // console.warn(resp)
        setUser(resp.data)
      })
    })
  }, [])

  const handleChange = (event) => {
    if (event.target.value=="1" || event.target.value=="7" ) {
      setReferral("referral");
    }
    if (event.target.value=="2" ) {
      setReferral("staff");
     // setText("Please Enter In The Number Of The Restaurant Staff Person <br /> Who Referred You So They Can Get Credit For Referring You");
      setShowContent(false); // Show content only when a value is selected
      setStaffContent(true);
    } 

    if (event.target.value=="1"){
      //setText("Please Enter In The Number Of The BOGOmazing Member <br /> Who Referred You So They Can Earn A FREE Month With BOGOmazing");
      setShowContent(true);
      setStaffContent(false);
    }

    if (event.target.value=="3" || event.target.value=="4" || event.target.value=="5" || event.target.value=="6"){
      //setText(""); 
      setReferral("");
      setShowContent(false);
      setStaffContent(false);
    }


  };
  const handleInputChange = (e, setter, maxLength) => {
    let value = e.target.value;
    
    // Truncate the input to the specified maxLength
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    setter(value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
   
    if (form.checkValidity() === false) {
     
      event.stopPropagation();
    }else{

      const isValid = validateCodes();

      if (isValid) {
        const data = new FormData(event.target);
        data.append('token', token);
        
        fetch(process.env.REACT_APP_API_URL+'verifyRefferal', {
          method: 'POST',
          mode: 'cors', 
          body: data, 
        })
          .then((result) => {

              result.json().then((resp) => {

                if(resp.status==="error"){ 
                  toast.error(resp.message);
                }
                else{
                  toast.success(resp.message);
                  window.location.replace(process.env.REACT_APP_BASE_URL+"Payment/"+token);
                }  
            
            })
          })
          .catch((error) => {
            console.error(error);
          });

      }
    }
    setValidated(true);

  
  };


  return (
    <>

      <div className="app">
      <ToastContainer />
        <br />
        <br />
        <div className="login-form text-center">
          <div className="title text-center p-4 mb-4 fs-4">How Did You Find Out About BOGOmazing</div>
          
          <div className='container p-4'>
              {showContent && (
              <>
                <p>Please Enter In The Number Of The BOGOmazing Member </p>
                <p>Who Referred You So They Can Earn A FREE Month With BOGOmazing</p>
                <br />
              </>
            )}
              {showStaffContent && (
              <>
                <p>Please Enter In The Number Of The Restaurant Staff Person</p>
                <p>Who Referred You So They Can Get Credit For Referring You</p>
                <br />
              </>
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridState">

                  <Form.Select name="findBogoID" required type="select" onChange={handleChange}>
                    <option disabled selected hidden>Please Select How You Found Out About BOGOmazing</option>
                      { 
                        users.map((item,i)=>
                            <option value={item.id}>{item.name}</option>
                        
                      )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please Select Type
                  </Form.Control.Feedback>
                </Form.Group>

              </Row>
              {(() => {
         
                if (referral=="referral") {
                  return <Row className="mb-3 align-items-center justify-content-center text-center" >
                  <Form.Group as={Col} md="2">
                    <Form.Control type="text" className='text-center' value={code1} onChange={(e) => handleInputChange(e, setCode1, 2)} name="code1" placeholder="00" style={code1Error ? errorStyle : {}} required />
                   </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Control type="text" className='text-center' value={code2} onChange={(e) => handleInputChange(e, setCode2, 3)} name="code2" placeholder="000" style={code2Error ? errorStyle : {}} required />
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Control type="text" className='text-center' name="code3" value={code3}
                       onChange={(e) => handleInputChange(e, setCode3, 5)} placeholder="00000" style={code3Error ? errorStyle : {}} required />
                  </Form.Group>
                </Row>;   
                }else
                if (referral=="staff") {
                  return <Row className="mb-3 align-items-center justify-content-center text-center">
                  <Form.Group as={Col} md="2">
                      <Form.Control type="text" className='text-center' name="code3" value={code3} onChange={(e) => handleInputChange(e, setCode3, 5)}  placeholder="00000" style={code3Error ? errorStyle : {}} required />
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                      <Form.Control type="text" className='text-center' name="code1" value={code1} onChange={(e) => handleInputChange(e, setCode1, 2)} placeholder="00" style={code1Error ? errorStyle : {}} required />
                     </Form.Group>
    
                    <Form.Group as={Col} md="2">
                      <Form.Control type="text" className='text-center' name="code2" value={code2} onChange={(e) => handleInputChange(e, setCode2, 3)}  placeholder="000" style={code2Error ? errorStyle : {}} required />
                    </Form.Group>
                  </Row>;  
                }else{

                  return null;
                }
               
               
              })()}
              <Form.Control.Feedback type="invalid" style={errorAlertStyle}>
                  {code1Error && code2Error && code3Error}
                </Form.Control.Feedback>
             
              <Button variant="danger" size="md" type="submit">
              Step 4 of 4  Proceed To Payment Screen
              </Button>
            </Form>
          </div>
        </div>
        <br />
        <br />
      </div>

    </>
  );
}

export default FindBogo;