import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
const Eula = () => {
    return (
   <div>
    <br/>
  
    <Container>
        <Row>
         
         <p>Please read this Mobile Application End User License Agreement (“EULA”) carefully before downloading or using the BOGOmazing Restaurant Dining Application (“BOGOamzing”) mobile application (“Mobile App”), which allows You to access BOGOmazing’s Restaurant Dining Discount Program(“Discount Dining Subscription”) from Your mobile device. This EULA forms a binding legal agreement between you and BOGOmazing as of the date you download the Mobile App. Your use of the Mobile App is subject to this EULA and Your use of the Subscription Service will remain subject to the existing agreement governing such use (the “Subscription Agreement”). With respect to the use of the Mobile App, and to the extent the Subscription Agreement conflicts with this EULA, the terms of this EULA will govern and control solely with respect to use of the Mobile App. <br /><br />
            1.  License.  BOGOmazing grants You a revocable, non-exclusive, non-transferable, limited license to receive restaurant discounts as outlined in BOGOmazing at any restaurant listed in BOGOmazing in accordance with this EULA and the Subscription Agreement. <br /><br />
            2. Your Account. Your use of the Mobile App requires that You have an account with BOGOmazing and agree to the terms of the Subscription Agreement. <br /><br />
            3. Changes to this EULA. BOGOmazing reserves the right to modify this EULA at any time and for any reason. BOGOmazing will post the most current version of this EULA at <Link to={" https://www.bogomazing.com/Eula"} className='text-reset'>
            https://www.bogomazing.com/Eula
                </Link>. If BOGOmazing makes material changes to this EULA, You will receive notification via the Mobile App. Notwithstanding the foregoing, You are responsible for complying with the updated terms posted online at BOGOmazing’s website even if these updated terms appear online at BOGOmazing’s website before being posted on the Mobile App. Your continued use of the Mobile App after BOGOmazing publishes notice of changes to this EULA indicates Your consent to the updated terms.<br /><br />
            4. No Included Maintenance and Support. BOGOmazing may deploy changes, updates, or enhancements to the Mobile App at any time. BOGOmazing may provide maintenance and support for the Mobile App, but has no obligation whatsoever to furnish such services to You and may terminate such services at any time without notice. You acknowledge that neither Apple (for iOS Mobile App) nor Google (for Android Mobile App) has an obligation to furnish any maintenance or support services in connection with the Mobile App.<br /><br />
            5. Acceptable Use. You agree that You will not use or encourage others to use the Mobile App or the Subscription Service as accessed through the Mobile App in a way that could harm or impair others’ use of the Mobile App or the Subscription Service. Your use of the Subscription Service and the Mobile App is governed by the Acceptable Use Policy (a current version of which can be found at <Link to={"https://www.bogomazing.com/PrivacyPolicy"} className='text-reset'>
            https://www.bogomazing.com/PrivacyPolicy</Link>. You also agree not to violate the usage limits or controls set forth by: (a) the App Store Terms of Service, for iOS users accessing the Mobile App on an Apple product, or (b) Google Play Terms of Service for Android users accessing the Mobile App on an Android product.   <br /><br />
            6. Privacy. In order to operate and provide the Subscription Service and the Mobile App, BOGOmazing may collect certain information about You, including technical and telemetry data related to your use of the Mobile App. We use third party service providers to help us collect and analyze this data, including Google Analytics. BOGOmazing uses and protects that information in accordance with the BOGOmazing Privacy Notice (a current version of which can be found at <Link to={"https://www.bogomazing.com/PrivacyPolicy"} className='text-reset'>
            https://www.bogomazing.com/PrivacyPolicy
                </Link>)).  <br /><br />
            7. Consent to Electronic Communications and Solicitation. By downloading the Mobile App, You authorize BOGOmazing to send You (including via email and push notifications) information regarding the Subscription Service and the Mobile App, such as: (a) notices about Your use of the Subscription Service and the Mobile App, including notices of violations of use; (b) updates to the Subscription Service and Mobile App and new features or products; and (c) promotional information and materials regarding BOGOmazing’s products and services.  You can review Your account notification settings and adjust Your messaging preferences, including opting-in to additional messages or unsubscribing to certain messaging through the “Notifications” section of the Mobile App settings.<br /><br />
            8. No Warranty. YOUR USE OF THE MOBILE APP IS AT YOUR SOLE RISK. THE MOBILE APP IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. BOGOMAZING EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
            The Mobile App is only available for supported devices and might not work on every device. Determining whether Your device is a supported or compatible device for use of the Mobile App is solely Your responsibility, and downloading the Mobile App is done at Your own risk. BOGOmazing does not represent or warrant that the Mobile App and Your device are compatible or that the Mobile App will work on Your device. 
            8.1      iOS Application. In the event of BOGOmazing’s failure to conform to any applicable warranty, You may notify Apple, and Apple will refund the purchase price for the Mobile App. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, APPLE WILL HAVE NO OTHER WARRANTY OBLIGATION WHATSOEVER WITH RESPECT TO (A) THE MOBILE APP, AND (B) ANY OTHER CLAIMS, LOSSES, LIABILITIES, DAMAGES, COSTS, OR EXPENSES ATTRIBUTABLE TO ANY FAILURE TO CONFORM TO ANY WARRANTY.  
            8.2      Android Application. GOOGLE EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.<br /><br />
            9. Suspension and Termination of the Mobile App. BOGOmazing reserves the right to suspend or terminate Your access to the Mobile App at any time based on the status of Your account under the Subscription Agreement. You understand that if Your account is suspended or terminated, You may no longer have access to the content that is stored within the Subscription Service. <br /><br />
            10.  Intellectual Property Rights. In the event of a third party claim that the Mobile App, or Your possession and use of the Mobile App, infringes third party’s intellectual property rights, BOGOmazing will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.<br /><br />
            11. Legal Compliance. You represent and warrant that: (a) You are not located in a country that is subject to a United States Government embargo, or that is on Title 15, Part 740 Supplement 1 Country Group E of the United States Code of Federal Regulations; (b) You are not located in a country that has been designated by the United States Government as a “terrorist supporting” country; and (c) You are not listed on any U.S. Government list of prohibited or restricted parties. You further agree not to transport the Mobile App to or use the Mobile App in any such country. <br /><br />
            12. Governing Law. This EULA shall be governed by and construed in accordance with the laws governing Your Subscription Agreement. <br /><br />
            13. Contact Information. If You have any questions regarding this EULA, please contact BOGOmazing by email at legal@bogomazing.com.<br /><br />
            14. Third Party Beneficiaries. This EULA is executed between You and BOGOmazing and not between you and any other party, including Apple for iOS users and Google for Android users. You agree that any claims brought by You arising out of this EULA or Your use of the Mobile App will not be made against Apple or Google, as applicable. Notwithstanding the foregoing, upon Your acceptance of this EULA, allows Apple or Google, as applicable, to enforce this EULA against You as a third party beneficiary thereof. BOGOmazing is not responsible for any applicable third-party agreement between You and any third-party, including your wireless provider. <br />

            </p>
			</Row>
            </Container>  		
		
		

    </div>
  )
  };


export default Eula;
