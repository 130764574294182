import React, { Component } from 'react';

class SingleRowComponent extends Component {
  render() {
    const rowData = this.props.rowData; // Assuming rowData is passed as a prop

    return (
        <tbody>
            <tr>
                <td>BOGOmazing Member #:</td>
                <td>{rowData.promocode}</td>
                <td>First name:</td>
                <td>{rowData.memberfirstname}</td>
            </tr>
            <tr>
                <td>Last name:</td>
                <td>{rowData.memberlastname}</td>
                <td>Email Address:</td>
                <td>{rowData.memberemail}</td>
                
            </tr>
            <tr>
                <td>Password:</td>
                <td>{rowData.pass_code}</td>
                <td>Sex:</td>
                <td>{rowData.gender}</td>
                
            </tr>
            <tr>
                <td>DOB:</td>
                <td>{rowData.DOB}</td>
                <td>Phone:</td>
                <td>{rowData.usphoneno}</td>
                
            </tr>
            <tr>
                <td>Card Type:</td>
                <td>{rowData.card_type}</td>
                <td>Last 4 Digits:</td>
                <td>{rowData.last_four_digit}</td>
                
            </tr>
            
            </tbody>
      
    );
  }
}

export default SingleRowComponent;
